import React from "react";
import { Link } from "gatsby";
import Modal from "../../components/ModalVideo/Modal";
import { useStaticQuery, graphql } from "gatsby";
import imgSBR from "../../assets/image/home-2/png/round-shape-blue.png";
import imgBP from "../../assets/image/home-2/png/dot-pattern-blue.png";
import imgH from "../../assets/image/home-2/png/mobile-app.png";
import imgH2 from "../../assets/image/home-2/png/mac.png";
import nk_pl from "../../assets/image/nk_fly.png";

import { withTrans }  from '../i18next_setting/withTrans'
import { useTranslation } from "react-i18next"

import Particles from "react-particles-js";
import "./animation_img.css";

const Hero = ({ className, ...rest }) => {
  const { t } = useTranslation()

  function handleScroll() {
    window.scroll({
      top: document.body.offsetHeight,
      left: 0, 
      behavior: 'smooth',
    });
  }

  return (
    <>
      <Particles
        params={{
          particles: {
            line_linked: {
              shadow: {
                enable: true,
                color: "#035397",
                blur: 1,
              },
            },
          },
        }}
        style={{
          width: "100%",
          position: "absolute",
        }}
      />
      <div className={className} {...rest}>
        <div className="container-fluid">
          <div className="row justify-content-center">
            {/* <!-- Hero Content --> */}
            <div
              className="col-xl-5 col-lg-6 col-md-8 col-xs-10 order-2 order-lg-1"
              data-aos="fade-right"
              data-aos-delay="500"
            >
              <div className="pr-lg-10 pr-xl-0 pt-0 pt-lg-0 pb-13 pb-lg-0 text-header-page-app" >
{/*       <button onClick={() => i18n.changeLanguage("th")}>th</button>
      <button onClick={() => i18n.changeLanguage("en")}>en</button>  */}
  
                <h1 className="font-size-11 mb-8">
                 {t("Let us help your business with technology")}
                </h1>
                <p className="font-size-7 mb-0 heading-default-color">
                {t("To meet the goals of your business, you are welcome to consult before starting work without any cost")}
                </p>
                <div className=" pt-5 pt-lg-10">
                  <button
                    className="btn  rounded-5"
                    style={{
                      backgroundColor: "#035397",
                      borderColor: "#035397",
                      color: "#FFF",
                    }}
                    onClick={handleScroll}
                  >
                    {t("Contact us")}
                  </button> 
                </div>
     
                <img src={nk_pl} alt="" className="nk-pl-animation-fly" style={{ marginTop:"100px" }}/>
               
              </div>
              
            </div>
            {/* <!-- End Hero Content --> */}
            {/* <!-- l2-hero-image-group --> */}
            <div
              className="col-xl-7 col-lg-6 col-md-7 order-1 order-lg-2"
              data-aos="fade-left"
              data-aos-delay="800"
            >
              <div className="l2-hero-image-group mt-5 mt-lg-0">
                <div className="img-1">
                  <img src={imgSBR} alt="" />
                </div>
                <div className="img-2" style={{ zIndex:'-1' }} >
                  <img src={imgBP} alt="" className="img-animation-up-down" />
                </div>
                <div className="img-3" style={{ zIndex:'5' }} >
                  <img src={imgH} alt="" className="img-animation-left-right"/>
                </div>
                <div className="img-4">
                  <img
                    src={imgH2}
                    alt=""
                    className="img-animation-left-right"
                  />
                </div>

                {/* <!-- End Image Group Content --> */}
              </div>
            </div>
            {/* <!-- End l2-hero-image-group --> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default withTrans(Hero)