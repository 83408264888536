import React from "react";
import web_application from '../../../assets/image/home-2/png/solutions/w1.jpg'
import mobile_app from '../../../assets/image/home-2/png/solutions/m2.jpg'
import image_process from '../../../assets/image/home-2/png/solutions/img_p2.jpg'
import ai from '../../../assets/image/home-2/png/solutions/ai1.jpg'

export default function Solution() {

    const solutions_all = [
        {
          name : 'Web Application',
          image : web_application
        },
        {
          name : 'Mobile Application',
          image : mobile_app
        },
        {
          name : 'Image Processing',
          image : image_process
        },
        {
          name : 'AI',
          image : ai
        }
      ]

  const block = {
    width: "250px",
    height: "350px",
    display: "inline-block",
    margin: "1rem",
    backgroundColor: "white",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  };
  return (
    <div style={{ textAlign: "center", width: "100%" }}>
      <h2 style={{ marginBottom:'100px' }}>SOLUTIONS</h2>
      {
        solutions_all.map( (data , index) => (
            <div style={block} key={index}>
            <img src={data.image}  alt="web_application" style={{ width:'100%' }} />
            <p style={{ marginTop:'50px' }}> {data.name} </p>
            </div>
        ))
      }
    </div>
  );
}
