import React from "react";
import { useTranslation } from "react-i18next"
import { withTrans }  from '../i18next_setting/withTrans'

import brand1 from "../../assets/image/home-2/png/pnall.png";
import brand2 from "../../assets/image/home-2/png/nectec.png";
import brand3 from "../../assets/image/home-2/png/distar.png";
import brand4 from "../../assets/image/home-2/png/cpf.png";

const SingleBrand = ({image}) => {
  return (
    <>
      {/* Single Brand */}
      <div className="single-brand-logo mx-5 my-6">
        <img src={image} alt="" />
      </div>
      {/* Single Brand */}
    </>
  );
}

const Company = ({ className, ...rest }) => {
  const { t } = useTranslation()
    const images = [
      brand2,
      brand3,
      brand4
    ];
  return (
    <>
      {/* Company Section */}
      <div className={className} {...rest}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="mb-lg-8 text-center text-lg-center">
                <h5 className="font-size-6 font-weight-normal text-bali-gray">
                {t("Our Client & Partners")} 
                </h5>
              </div>
            </div>
          </div>
          {/* Brand Logos */}
          <div className="brand-logo-small d-flex align-items-center justify-content-center justify-content-lg-between flex-wrap">
            {images.map((brand, index) => (
              <SingleBrand image={brand} key={index} />
            ))}
          </div>
        </div>
      </div>
      {/* End Company Section */}
    </>
  );
};

export default withTrans(Company)