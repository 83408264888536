import React, { useState } from "react";
import { Helmet } from "react-helmet";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/home2/Hero";
import Company from "../sections/home1/Compnay";
import Progress from "../sections/home2/Progress";
import Content2 from "../sections/home2/Content2";
import Content3 from "../sections/home2/Content3";
import Testimonial from "../sections/home2/Testimonial";
import Seo from "../sections/home2/components/Seo";
import warning_announce from "../assets/image/warning_announce.png";
import { MdClose } from "react-icons/md";
import { Modal } from "antd";

const Home2 = () => {
  const [visible, setVisible] = useState(true);
  function onOk() {
    setVisible(false);
  }
  return (
    <>
      <Seo />
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: (
            <>
              <button className="btn btn btn-dodger-blue-2 header-btn rounded-5">
                Get Started
              </button>
            </>
          ),
          footerStyle: "style1",
        }}
      >
        <Hero className="pt-11 pt-lg-34 pb-8 pb-lg-33" />
        <Company className="bg-default-1 pt-13 pt-md-17 pt-lg-24 pb-13 pb-md-14 pb-lg-23" />
        <Progress className="pb-lg-21 border-top border-default-color-1" />

        <Content2 className="pb-0 pb-lg-31" />

        <Modal
          closable={false}
          open={visible}
          onCancel={() => onOk()}
          footer={null}
          centered
        >
          <div style={{ position: "relative" }}>
            <div
            onClick={() => onOk()}
              style={{
                position: "absolute",
                zIndex: "2",
                cursor: "pointer",
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                background: "#f54949",
                right:'-17px',
                top:'-17px',
                boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
                
              }}
            >
              <MdClose size="30px" style={{ color: "#FFFF" , marginTop:'5px' , marginLeft:'5px' }} />
            </div>
            <img
              src={warning_announce}
              style={{ width: "100%", position: "relative" }}
            />
          </div>
        </Modal>

        <Testimonial className="pt-lg-19 pb-12 pb-lg-17" />
      </PageWrapper>
    </>
  );
};
export default Home2;
