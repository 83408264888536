import React from "react";
import Solution from "./components/Solution";
const Testimonial = ({ className, ...rest }) => {
  return (
    <>
      <div style={{ margin: "50px" }} className={className} {...rest}>
        <div
          className="container-fluid"
          data-aos="zoom-in"
          data-aos-delay="300"
          data-aos-once="true"
        >
          <div className="row">
            <div className="col-12">
              <Solution />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
